import {Form} from 'react-final-form'
import {isFunction} from 'lodash'
import getDisplayName from 'react-display-name'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import {validator} from './forms'


export const createValidate = (schema = {}, options = {}) => {
  return validator(schema.valueOf({isRoot: false, ...options}))
}

const defaultParse = (values) => values
const withForm = ({schema, parse: providedParse, validate: providedValidate, subscription, validateOnBlur = false} = {}) => (WrappedComponent) => {
  const validate = providedValidate || createValidate(schema)
  const parse = providedParse || defaultParse
  const Final = ({onSubmit, children, innerProps, ...rest}) => {
    return (
      <Form
          mutators={{
            ...arrayMutators,
          }}
          keepDirtyOnReinitialize
          onSubmit={(values) => onSubmit(validate(parse(values)).values)}
          validate={(values) => validate(parse(values)).errors}
          validateOnBlur={validateOnBlur}
          subscription={subscription}
          {...rest}
      >
        {({handleSubmit, ...formProps}) => {
          const form = (
            <WrappedComponent handleSubmit={handleSubmit} {...formProps} {...innerProps} />
          )

          if (isFunction(children)) {
            return (
              <form onSubmit={handleSubmit}>
                {children({handleSubmit, ...formProps, form})}
              </form>
            )
          }
          return (
            <form onSubmit={handleSubmit}>
              {form}
            </form>
          )
        }}
      </Form>
    )
  }

  Final.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.func,
    innerProps: PropTypes.object,
  }

  Final.displayName = `FinalForm(${getDisplayName(WrappedComponent)})`
  return Final
}

export default withForm
